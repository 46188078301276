
.order-compensation {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-bottom: 10px;
    .form-box {
        box-sizing: border-box;
        width: 100%;
        padding: 30px 20px 30px;
        .money-input {
            width: 84px;
        }
        .hint {
            margin-left: 4px;
            color: #606266;
        }
        .apply {
            display: flex;
            justify-content: flex-end;
            .apply-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 30px;
                font-size: 12px;
                line-height: 0;
                color: #fff;
                background-color: #006AFF;
                border: none;
                &:hover {
                    background-color: #4c96ff;
                }
                &.disabled {
                    background-color: #96caff !important;
                }
            }
        }
    }
    .compensation-record {
        flex: 1;
        width: 100%;
        font-size: 14px;
        color: #606266;
        overflow: hidden;
        .title,
        .table-head,
        .table-content .item {
            box-sizing: border-box;
            padding: 10px 20px;
            display: flex;
            overflow: hidden;
        }
        .table-head,
        .table-content .item {
            .origin {
                flex: 13;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .money {
                flex: 8;
                text-align: center;
            }
            .remark {
                flex: 10;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .table-head {
            border: 1px solid #EAECF0;
            background-color: #F3F6F9;
        }
        .table-content {
            display: flex;
            flex-direction: column;
            .item {
                display: flex;
                border-bottom: 1px solid #EAECF0;
            }
        }
    }
}
::v-deep .restriction .el-input__inner {
    height: 36px;
}
::v-deep .el-form {
    .el-form-item {
        margin-bottom: 20px;
    }
}
