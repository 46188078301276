
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.15);
    z-index: 9;
    .user-info {
        display: flex;
        align-items: center;
        color: #696970;
        font-size: 14px;
        .avatar {
            box-sizing: border-box;
            width: 32px;
            height: 32px;
            margin-right: 8px;
            border-radius: 50%;
            border: 1px solid #E6E7EB;
            vertical-align: bottom;
            overflow: hidden;
        }
    }
    .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        font-size: 12px;
        color: #fff;
        background-color: #006AFF;
        border: none;
    }
}
