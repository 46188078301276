
.coupon {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 112px;
    padding: 13px 0;
    color: #E8392C;
    background-color: #FDF6F2;
    border-radius: 4px;
    margin-top: 10px;
    .left-info {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        .core-info {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .price {
                margin-right: 9px;
                font-size: 38.25px;
                font-weight: bold;
                .decorate {
                    margin-right: 2px;
                    font-size: 17.71px;
                }
            }
            .type-info {
                font-size: 17.57px;
                font-weight: bold;
                .type {
                    margin-top: 2px;
                    font-weight: normal;
                    font-size: 13.24px;
                }
            }
        }
        .expiry-date {
            margin-bottom: 4px;
            font-size: 13.24px;
            text-align: center;
        }
    }
    .line {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 1px;
        height: 100%;
        margin-left: 3%;
        .line-item {
            width: 100%;
            height: 4px;
            background-color: #E8392C;
        }
    }
    .right-send {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 2px;
        cursor: pointer;
    }
    &.already {
        background-color: #E8E8E8;
        .left-info,
        .right-send {
            color: #A5A5A5;
        }
        .line .line-item{
            background-color: #A5A5A5;
        }
    }
}
