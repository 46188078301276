
.customer-service {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .containers {
        box-sizing: border-box;
        display: flex;
        flex: 1;
        /* width: 100%; */
        background-color: #f8f7fc;
        overflow: hidden;
        .main {
            flex: 1;
            display: flex;
            padding: 20px 20px 10px 10px;
            /* overflow: hidden; */
        }
    }
    .tab-containers {
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
    }
}
