
$themeColor: #006AFF;
.operation-bar {
    box-sizing: border-box;
    /* flex: 36; */
    display: flex;
    flex-direction: column;
    width: 360px;
    min-width: 360px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #EAECF0;
    overflow: hidden;
    .tabs {
        box-sizing: border-box;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        border-bottom: 1px solid #E8E6EB;
        cursor: default;
        .tab-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            cursor: pointer;
            transition: color .3s;
            border-bottom-width: 2px;
            border-bottom-color: transparent;
            border-bottom-style: solid;
            transition: all .3s;
            &:hover {
                color: $themeColor;
            }
        }
        .active-tab {
            color: $themeColor;
            font-weight: 700;
            border-bottom-color: $themeColor;
        }
    }
    .tab-content {
        flex: 1;
        display: flex;
        overflow: hidden;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
