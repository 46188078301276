
$themeColor: #006AFF;

.goods-list {
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .input-box {
        padding: 10px 20px 0;
        margin-bottom: 20px;
        .custom-input {
            width: 100%;
            ::v-deep .el-input__inner {
                height: 30px;
            }
        }
    }
    
    .content {
        flex: 1;
        padding-bottom: 20px;
        overflow: hidden;
        .tab-containers {
            padding: 0 20px;
            .btn-send {
                color: $themeColor !important;
            }
            .btn-delete {
                color: #EF2626;
            }
        }
    }
}

