
.empty {
    box-sizing: border-box;
    width: 100%;
    padding: 20% 20% 0;
    text-align: center;
    &.small {
        padding-top: 15%;
    }
    .empty-img {
        width: 100%;
    }
    .hint {
        margin-top: 30px;
        font-size: 14px;
        color: #606266;
    }
}
